<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Таблица с пользователями"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-icon style="font-size: 32px;">
            mdi-clipboard-text
          </v-icon>
          Таблица с пользователями
        </div>
      </template>
      <template v-slot:after-heading>
        <JsonExcel
          :class="`v-btn v-btn--text theme--${$vuetify.theme.dark ? 'dark' : 'light'} v-size--large mt-1`"
          :fetch="fetchData"
          :fields="json_fields"
          type="xls"
          name="пользователи.xls"
        >
          <span class="v-btn__content">
            Скачать
            <v-icon>
              mdi-download
            </v-icon>
          </span>
        </JsonExcel>
      </template>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск"
        single-line
        hide-details
      />
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="users"
        :search="search"
        :expanded.sync="expanded"
        item-key="user_id"
        show-expand
        show-select
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text':'Пользователей на странице'
        }"
        @click:row="clickRow"
      >
        <template
          v-slot:item.tg_uid="{ item }"
        >
          {{ item.tg_uid }}
        </template>
        <template
          v-slot:item.bot="{ item }"
        >
          <div
            v-for="(child, i) in getItemBots(item.bot)"
            :key="`bot-${i}`"
          >
            <v-chip
              :color="getBotColor(child)"
              class="mb-1 mt-1"
              dark
            >
              {{ child }}
            </v-chip>
          </div>
        </template>
        <template
          v-slot:item.role="{ item }"
        >
          <div
            v-for="(child, i) in getItemRoles(item.role)"
            :key="`role-${i}`"
          >
            <v-chip
              :color="getRoleColor(child)"
              class="mb-1 mt-1"
              dark
            >
              {{ child }}
            </v-chip>
          </div>
        </template>
        <template v-slot:expanded-item="{ eheaders, item }">
          <td :colspan="headers.length">
            <v-textarea
              :value="item.projects"
              :readonly="true"
              :auto-grow="true"
              solo
              flat
              hide-details
            />
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="mx-7">
            <v-icon
              small
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </base-material-card>

    <v-dialog
      v-model="projectsModal"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Проект {{ activeProject.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="projectsModal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col cols="12">
              Роль: {{ activeProject.role }}
            </v-col>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(week, i) in activeProject.weeks"
                  :key="i"
                >
                  <v-expansion-panel-header>Неделя {{ week.seq_num }}</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-2">
                    <div v-if="isEmployee(activeProject.role)">
                      <p>Ответы на вопрос №1</p>
                      <div>
                        <v-textarea
                          :value="getFirstQuestionAnswers(week, activeProjectUserId)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №2</p>
                      <div>
                        <v-textarea
                          :value="getSecondQuestionAnswers(week, activeProjectUserId)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №3</p>
                      <div>
                        <v-textarea
                          :value="getThirdQuestionAnswers(week, activeProjectUserId)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                    </div>

                    <div v-if="isDirector(activeProject.role)">
                      <div
                        v-for="(status, si) in getConsolidatedStatus(week.statuses)"
                        :key="si"
                      >
                        <p class="mt-4 mb-4">
                          Согласованный сводный статус
                        </p>
                        <v-textarea
                          :value="status.consolidated_text"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                    </div>

                    <div v-if="isClient(activeProject.role)">
                      <div
                        v-for="(status, si) in getConsolidatedStatus(week.statuses)"
                        :key="si"
                      >
                        <p class="mt-4 mb-4">
                          Согласованный сводный статус
                        </p>
                        <v-textarea
                          :value="status.consolidated_text"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                    </div>

                    <div v-if="isAdmin(activeProject.role)">
                      <p>Ответы на вопрос №1</p>
                      <div>
                        <v-textarea
                          :value="getFirstQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №2</p>
                      <div>
                        <v-textarea
                          :value="getSecondQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №3</p>
                      <div>
                        <v-textarea
                          :value="getThirdQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <div
                        v-for="(status, si) in getConsolidatedStatus(week.statuses)"
                        :key="si"
                      >
                        <p class="mt-4 mb-4">
                          Согласованный сводный статус
                        </p>
                        <v-textarea
                          :value="status.consolidated_text"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-btn
            class="mt-6"
            rounded
            :disabled="!prevProjectBtn"
            @click="prevProject()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            class="mt-6"
            rounded
            :disabled="!nextProjectBtn"
            @click="nextProject()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Вы уверены что хотите удалить пользователя?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >
            Да
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogDelete = false"
          >
            Отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import UserService from '../../../services/UserService'
  import ProjectService from '../../../services/ProjectService'
  import WeekService from '../../../services/WeekService'
  import AnswerService from '../../../services/AnswerService'
  import moment from 'moment-timezone'
  import ConsolidateStatusService from '../../../services/ConsolidateStatusService'

  export default {
    data () {
      return {
        search: '',
        selected: [],
        expanded: [],
        headers: [
          { text: 'Telegram UID', value: 'tg_uid', class: 'bold--text display-1' },
          { text: 'Имя пользователя (никнейм)', value: 'username', class: 'bold--text display-1' },
          { text: 'Дата регистрации', value: 'created_at', class: 'bold--text display-1' },
          { text: 'Бот', value: 'bot', class: 'bold--text display-1' },
          { text: 'Роль', value: 'role', class: 'bold--text display-1' },
          { text: 'Проекты', value: 'data-table-expand', class: 'bold--text display-1' },
          { text: 'Действия', value: 'actions', class: 'bold--text display-1 mx-auto', sortable: false },
        ],
        users: [],
        json_fields: {
          'Telegram UID': 'tg_uid',
          'Имя пользователя (никнейм)': 'username',
          'Дата регистрации': 'created_at',
          Бот: 'bot',
          Роль: 'role',
          Проекты: 'projects',
        },
        projectsModal: false,
        activeProject: {
          id: '',
          name: '',
          role: '',
          weeks: [
            {
              seq_num: 1,
              statuses: [
                {
                  answers: [
                    {
                      user_id: '',
                      answers_text: [
                        {
                          type: '',
                          text: '',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          index: 0,
        },
        activeProjects: [],
        activeProjectUserId: null,
        nextProjectBtn: true,
        prevProjectBtn: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          _id: '',
        },
      }
    },

    async mounted () {
      const self = this
      const usersData = await UserService.getAll()
      self.users = []

      for (const userData of usersData) {
        const bots = []
        const projects = []
        const projectsInfo = []
        const roles = []
        const roleNames = []
        let projectsCounter = 0

        // if (userData.projects.length === 0) {
        //   continue
        // }

        for (const project of userData.projects) {
          if (project.status === ProjectService.STATUS_ARCHIVE) {
            continue
          }

          const userProject = project.users.find((user) => JSON.stringify(user.user_id) === JSON.stringify(userData._id))
          if (!userProject) {
            continue
          }

          projectsCounter += 1
          const userRolename = ProjectService.getRolename(userProject.role)
          projects.push('№' + projectsCounter + ' \n' + 'ID: ' + project._id + ' \nНазвание: ' + project.name + ' \nРоль: ' + userRolename)
          projectsInfo.push({
            id: project._id,
            name: project.name,
            role: userRolename,
          })
          if (!roles.includes(userProject.role)) {
            roles.push(userProject.role)
            roleNames.push(userRolename)
          }
        }

        // if (projectsCounter === 0) {
        //   continue
        // }

        if (roles.includes(ProjectService.ROLE_ADMIN)) {
          bots.push('АдминБот')
        }

        if (roles.includes(ProjectService.ROLE_DIRECTOR) || roles.includes(ProjectService.ROLE_EMPLOYEE) || roles.includes(ProjectService.ROLE_CLIENT)) {
          bots.push('СтатусБот')
        }

        self.users.push({
          user_id: userData._id,
          tg_uid: userData.tg_uid,
          username: userData.fio + ' ' + `(${userData.username})`,
          created_at: moment(userData.createdAt).tz('Europe/Moscow').format('DD.MM.YYYY HH:mm [по мск.]'),
          bot: bots.join('\n'),
          role: roleNames.join('\n'),
          projects: projects.join('\n'),
          projectsInfo: projectsInfo,
        })
      }
    },

    methods: {
      fetchData () {
        const self = this
        const usersJson = []

        for (const selected of self.selected) {
          usersJson.push(selected)
        }

        if (usersJson.length === 0) {
          self.$snackbar.showMessage({ content: 'Выберите хотя бы 1 пользователя', color: 'warning' })
          return
        }

        return usersJson
      },
      getItemRoles (roleNames) {
        return roleNames.split('\n')
      },
      getItemBots (botNames) {
        return botNames.split('\n')
      },
      getRoleColor (roleName) {
        let color = ''
        switch (roleName) {
          case 'Сотрудник':
            color = 'blue'
            break
          case 'Руководитель':
            color = 'orange'
            break
          case 'Заказчик':
            color = 'green'
            break
          case 'Администратор':
            color = 'red'
            break
          default:
            break
        }
        return color
      },
      isEmployee (roleName) {
        return roleName === 'Сотрудник'
      },
      isDirector (roleName) {
        return roleName === 'Руководитель'
      },
      isClient (roleName) {
        return roleName === 'Заказчик'
      },
      isAdmin (roleName) {
        return roleName === 'Администратор'
      },
      getBotColor (botName) {
        let color = ''
        switch (botName) {
          case 'СтатусБот':
            color = 'indigo'
            break
          case 'АдминБот':
            color = 'teal'
            break
          default:
            break
        }
        return color
      },
      async updateActiveProject (project) {
        this.activeProject.id = project.id
        this.activeProject.name = project.name
        this.activeProject.role = project.role
        this.activeProject.weeks = await WeekService.getAll(null, project.id)
      },
      async clickRow (item, event) {
        if (this.dialogDelete) {
          return
        }

        this.activeProjectUserId = item.user_id
        const activeProject = item.projectsInfo[this.activeProject.index]

        if (activeProject) {
          await this.updateActiveProject(activeProject)
        }

        this.activeProjects = item.projectsInfo
        this.nextProjectBtn = (this.activeProjects.length > this.activeProject.index + 1)
        this.projectsModal = true
      },
      async nextProject (event) {
        this.activeProject.index += 1
        if (this.activeProjects.length <= this.activeProject.index + 1) {
          this.nextProjectBtn = false
        }
        const activeProject = this.activeProjects[this.activeProject.index] ? this.activeProjects[this.activeProject.index] : null

        if (activeProject) {
          await this.updateActiveProject(activeProject)
        }

        if (!this.prevProjectBtn) {
          this.prevProjectBtn = true
        }
      },
      async prevProject (event) {
        this.activeProject.index -= 1
        if ((this.activeProject.index - 1) < 0) {
          this.prevProjectBtn = false
        }
        const activeProject = this.activeProjects[this.activeProject.index] ? this.activeProjects[this.activeProject.index] : null

        if (activeProject) {
          await this.updateActiveProject(activeProject)
        }

        if (!this.nextProjectBtn) {
          this.nextProjectBtn = true
        }
      },
      getFirstQuestionAnswers (week, user_id = null) {
        const answers = AnswerService.getAnswers(week, AnswerService.TYPE_COMPLETED, user_id)
        return answers.join('\n')
      },
      getSecondQuestionAnswers (week, user_id = null) {
        const answers = AnswerService.getAnswers(week, AnswerService.STATUS_PROGRESS, user_id)
        return answers.join('\n')
      },
      getThirdQuestionAnswers (week, user_id = null) {
        const answers = AnswerService.getAnswers(week, AnswerService.STATUS_FINISHED, user_id)
        return answers.join('\n')
      },
      getConsolidatedStatus (statuses) {
        const consolidatedStatuses = statuses.filter((status) => status.type === ConsolidateStatusService.TYPE_AGREED)
        return consolidatedStatuses
      },
      deleteItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        const vm = this
        const user = vm.users[this.editedIndex]
        UserService.delete(user.user_id).then(response => {
          vm.users.splice(this.editedIndex, 1)
          vm.dialogDelete = false
          vm.$snackbar.showMessage({ content: 'Пользователь удален', color: 'success' })
        }).catch(error => {
          let errMessage = 'Что то пошло не так'
          if (error.response && error.response.data.message) {
            errMessage = error.response.data.message
          }
          vm.$snackbar.showMessage({ content: errMessage, color: 'error' })
        })
      },
    },
  }
</script>

<style>
  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: inherit;
  }
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: inherit;
  }
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    margin: 0;
  }
</style>
