import Vue from 'vue'

class UserService {
    async getAll () {
        try {
            return (await Vue.prototype.$http.get('/users')).data
        } catch (error) {
            return []
        }
    }

    delete (id) {
      return Vue.prototype.$http.delete(`/users/${id}`)
    }
  }
  export default new UserService()
